import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import update from 'immutability-helper';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Col, Empty, Form, Modal, Radio, Select, Space, Table, Row } from 'antd';
import { requestError } from '../../helpers/notification';
import { ReactComponent as PlusSvg } from '../../assets/icons/plus.svg';
import { ReactComponent as TrashSvg } from '../../assets/icons/trash.svg';
import DraggableBodyRow from '../common/tables/DraggableBodyRow';
import { JOB_FIELD } from '../../constants/Jobs';
import { selectAllCustomFields } from '../../redux/slices/app/planning.slice';
import { selectTabPreferences, updateTabPreferences } from '../../redux/slices/app/tab.slice';
import { refreshPlanningSelected } from '../../redux/thunks/planning-slice.thunk';
import CustomColorPicker from '../utils/CustomColorPicker';


const PlusIcon = styled(PlusSvg)`
    float: left;
    height: 32px;
    margin-left: 8px;
`;

const GroupingModal = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('translation', { keyPrefix: 'modal_grouping' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [form] = Form.useForm();
    const [isVisible, setVisible] = useState(false);
    const [customFields, setCustomFields] = useState([]);
    const [groupingType, setGroupingType] = useState('filter');
    const [groupingColor, setGroupingColor] = useState({});
    // const [planningColor, setPlanningColor] = useState();
    const [onlySimpleActivity, setOnlySimpleActivity] = useState(false);
    const allCustomFields = useSelector(selectAllCustomFields);
    const tabPreferences = useSelector(selectTabPreferences);


    useImperativeHandle(ref, () => ({
        openModal() {
            setVisible(true);
        },
    }));

    const onCancel = () => {
        setVisible(false);
    };

    const onOk = () => {
        form.submit();
        setVisible(false);
    };

    const submitForm = (values) => {
        let filteredGroupPreferences = {
            filtered_group: {
                groupingType: 'filter',
            },
        };

        if (values.grouping_type === 'custom_fields') {
            filteredGroupPreferences = {
                filtered_group: {
                    simpleActivityOnly: !!onlySimpleActivity,
                    sortOptions: values.options,
                    groupingType: 'custom_fields',
                },
            };
        }

        dispatch(updateTabPreferences(filteredGroupPreferences))
            .then(() => dispatch(refreshPlanningSelected()))
            .catch((error) => requestError(error, 'Erreur lors du groupement du Gantt'));
    };

    const onColorChange = (name, color) => {
        const currentOptions = form.getFieldValue('options');

        form.setFieldsValue({
            options: Object.assign([], currentOptions, {
                [name]: {
                    ...currentOptions[name],
                    color,
                },
            }),
        });
        form.setFieldsValue({
            grouping_color: color,
        });
        setGroupingColor((origin) => ({
            ...origin,
            [name]: color,
        }));
    };

    useEffect(() => {
        if (!isVisible) {
            return;
        }

        const filteredGroup = tabPreferences?.filtered_group;

        if (filteredGroup?.groupingType === 'custom_fields') {
            form.setFieldsValue({
                grouping_type: filteredGroup.groupingType,
                options: filteredGroup.sortOptions,
                only_simple_activities: filteredGroup.simpleActivityOnly,
            });
            setOnlySimpleActivity(filteredGroup.simpleActivityOnly);
            setGroupingType(filteredGroup.groupingType);
            setGroupingColor(filteredGroup.sortOptions.map(({ color }) => color));
        } else {
            form.setFieldsValue({
                grouping_type: 'filter',
            });
            setGroupingType('filter');
        }

        setCustomFields(
            // no filter by type.choice
            allCustomFields.map((item) => ({ label: item.name === JOB_FIELD ? generalTranslation('job_field') : item.name, value: item.id }))
        );
    }, [isVisible]);

    const moveRow = (dragIndex, hoverIndex) => {
        const rows = form.getFieldValue('options');
        const dragRow = rows[dragIndex];
        const newRows = update(rows, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragRow],
            ],
        });
        form.setFieldValue('options', newRows);
        console.log('🚀 ~ GroupingModal ~ newRows:', newRows);
    };

    return (
        <Modal
            title={t('activities_organization')}
            className="activities-grouping-modal"
            centered
            open={isVisible}
            width={912}
            onCancel={onCancel}
            onOk={onOk}
        >
            <Form form={form} name="grouping_form" layout="vertical" onFinish={submitForm}>
                <Form.Item name="grouping_type">
                    <Radio.Group onChange={(event) => setGroupingType(event.target.value)}>
                        <Space direction="vertical">
                            <Radio value="filter">{t('project_grouping')}</Radio>
                            <Radio value="custom_fields">{t('field_grouping')}</Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
                {groupingType === 'custom_fields' && (
                    <>
                        {customFields.length ? (
                            <>
                                <div className="ant-col ant-form-item-label">
                                    <label htmlFor="grouping_form_bars_color" title={t('options')}>
                                        {t('options')}
                                    </label>
                                </div>
                                <Form.List
                                    name="options"
                                    rules={[
                                        {
                                            validator: async (_, options) => {
                                                if (!options || options.length < 1) {
                                                    return Promise.reject(new Error(t('option_required')));
                                                }
                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    {(fields, { add, remove }, errors) => (
                                        <>
                                            <Table
                                                size="small"
                                                showHeader={false}
                                                pagination={false}
                                                dataSource={fields}
                                                components={{
                                                    body: {
                                                        row: DraggableBodyRow,
                                                    },
                                                }}
                                                onRow={(record, index) => ({
                                                    index,
                                                    moveRow,
                                                })}
                                                columns={[
                                                    {
                                                        key: 'key',
                                                        render: ({ key, name, fieldKey }) => {
                                                            console.log('🚀 ~ GroupingModal ~ field:');
                                                            return (
                                                                <Row gutter={16} key={key}>
                                                                    <Col span={11}>
                                                                        <Form.Item
                                                                            className="mb-0"
                                                                            name={[name, 'unifiedFieldId']}
                                                                            fieldKey={[fieldKey, 'unifiedFieldId']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: t('custom_field_required'),
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Select options={customFields} />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={11}>
                                                                        <Form.Item
                                                                            className="mb-0"
                                                                            name={[name, 'color']}
                                                                            fieldKey={[fieldKey, 'color']}
                                                                            initialValue="#000000"
                                                                        >
                                                                                <CustomColorPicker
                                                                                color={groupingColor[name]}
                                                                                onChange={(color) => onColorChange(name, color)}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={2}>
                                                                        <Button
                                                                            type="secondary"
                                                                            className="block w-full flex items-center justify-center cursor-pointer"
                                                                            onClick={() => remove(name)}
                                                                        >
                                                                            <TrashSvg />
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            );
                                                        },
                                                    },
                                                ]}
                                            />

                                            <Form.Item>
                                                <Button
                                                    type="secondary"
                                                    className="relative block w-full flex items-center justify-center mt-3 cursor-pointer"
                                                    onClick={() => add()}
                                                >
                                                    <PlusIcon className="absolute left-0 ml-3" />
                                                    {t('option_add')}
                                                </Button>
                                                {errors.errors && (
                                                    <div>
                                                        {errors.errors.map((error) => (
                                                            <div className="text-red-500">{error}</div>
                                                        ))}
                                                    </div>
                                                )}
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                                <Form.Item name="only_simple_activities">
                                    <Checkbox
                                        checked={onlySimpleActivity}
                                        onChange={(event) => setOnlySimpleActivity(event.target.checked)}
                                    >
                                        {t('only_simple_activities')}
                                    </Checkbox>
                                </Form.Item>
                            </>
                        ) : (
                            <Empty description={t('empty_custom_field')} />
                        )}
                    </>
                )}
            </Form>
            {/* (
                <Empty description="Aucune couleur de planning" />
            ) */}
        </Modal>
    );
});

export default GroupingModal;
