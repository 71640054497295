import { fetchEventSource } from '@microsoft/fetch-event-source';
import { Button, Modal, Result, Space, Typography } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectCanFetchData } from '../../redux/slices/app/user.slice';
import useMailTo from '../../hooks/useMailTo.hook';


const SSEErrorModal = forwardRef(({ onError }, ref) => {
    const sseTranslation = useTranslation('translation', { keyPrefix: 'sse_test' }).t;
    const [SSEStatusError, setSSEStatusError] = useState();
    const [isVisible, setVisible] = useState(false);
    const canFetchData = useSelector(selectCanFetchData)
    const SSETestAbortController = useRef();
    const { sendSSEProblemMail } = useMailTo()
    useImperativeHandle(ref, () => ({
        openModal() {
            setVisible(true);
        },
    }));

    const handleSSEConnectionError = ({ ok, redirected, message, status, statusText }) => {
        // Log the error message
        console.log('SSE CONNECTION ERROR');
        const error = { ok, redirected, message, status, statusText };
        setSSEStatusError(JSON.stringify(error));
        SSETestAbortController.current.abort();
        if (onError) onError(error);
        setVisible(true);
    };

    const testSSEConnection = () => {
        const accessToken = localStorage.getItem('accessToken');
        const headers = {};
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`;
            headers.Accept = 'text/event-stream';
        }
        SSETestAbortController.current = new AbortController();
        fetchEventSource(`${window._env_.REACT_APP_ACTIVITY_API}/broadcast-test-programsecure`, {
            signal: SSETestAbortController.current.signal,
            openWhenHidden: true,
            headers,
            onopen: (response) => {
                console.log('__________________SSE CONNECTION', response);
                if ((!response.ok && !response.redirected) || response.status !== 200) {
                    handleSSEConnectionError(response);
                }
            },
            onmessage: (e) => {
                console.log('🚀 ~ testSSEConnection ~ e:', e);
                if (e.data) {
                    SSETestAbortController.current.abort();
                    if (onError) onError(null);
                    setSSEStatusError(null);
                }
            },
            onerror: (err) => {
                try {
                    handleSSEConnectionError(err);
                    throw new Error();
                } catch (error) {
                    console.log('🚀 ~ testSSEConnection ~ error:', error);
                }
            },
            onclose: (err) => {
                try {
                    console.log('SSE CONNECTION TEST BROADCAST CLOSED', err);
                    throw new Error();
                } catch (error) {
                    console.log('🚀 ~ testSSEConnection ~ error:', error);
                }
            },
        });
    };

    const sendMailError = () => {
        sendSSEProblemMail(SSEStatusError);
    }

    useEffect(() => {
        if (canFetchData) {
            testSSEConnection();
        }
    }, [canFetchData]);

    const handleAccept = () => {
        setVisible(false);
    };

    return (
        <Modal
            open={isVisible}
            width={800}
            footer={
                <div className="flex justify-between">
                    <Button type="primary" onClick={() => testSSEConnection()}>
                        {sseTranslation('relaunch_test')}
                    </Button>
                    
                    <Button type="primary" onClick={() => handleAccept()}>
                        {sseTranslation('ok_text')}
                    </Button>
                </div>
            }
            closable={false}
            maskClosable={false}
            title={sseTranslation('warning')}
        >
            {SSEStatusError ? (
                <Result
                    status="warning"
                    title={sseTranslation('connexion_problem')}
                    subTitle={sseTranslation('connexion_problem_explication')}
                >
                    <Space direction="vertical">
                        <p>
                            <label>{sseTranslation('connexion_error')}</label>
                        </p>
                        <Typography.Text type="danger">
                            {sseTranslation('details')} <Typography.Text code>{SSEStatusError}</Typography.Text>
                        </Typography.Text>
                        <Button type="primary" onClick={() => sendMailError()}>{sseTranslation('contact_support_team')}</Button>
                    </Space>
                </Result>
            ) : (
                <Result
                    status="success"
                    title={sseTranslation('connexion_ok')}
                    subTitle={sseTranslation('no_problem')}
                />
            )}
        </Modal>
    );
});

export default SSEErrorModal;
