import React, {useState} from "react";
import { Steps } from "antd";
import { useTranslation } from "react-i18next";
import TaskImport from "./TaskImport";
import LinkImport from "./LinkImport";
import ImportResume from "./ImportResume";
import { generateString } from "../../helpers/string-helper";
/* eslint-disable */
const ImportExcelStepper = ({importData}) => {
    const {t} = useTranslation('translation', {keyPrefix: 'modal_import'});
    const [current, setCurrent] = useState(0);
    const [taskMappingData, setTaskMappingData] = useState();
    const [taskDataToSend, setTaskDataToSend] = useState([]);
    const [linkMappingData, setLinkMappingData] = useState();
    const [linkDataToSend, setLinkDataToSend] = useState([]);
  const goToStep = (step) => {
    setCurrent(step);
  };

  const onTaskImportOk = ({mapping, taskData}) => {
    setTaskMappingData(mapping);
    setTaskDataToSend(taskData);
    goToStep(1);
  }
  const onLinkImportOk = ({mapping, linkData}) => {
    setLinkMappingData(mapping);
    setLinkDataToSend(linkData);
    goToStep(2);
  }
  const prev = () => {
    setCurrent(current - 1);
  };
  const next = () => {
    setCurrent(current + 1);
  };
    const steps = [
        {
          key: generateString(7),
          title: t('gantt_data'),
          content: <TaskImport tasks={importData.tasks} onOk={onTaskImportOk} mappingData={taskMappingData}/>,
        },
        {
          key: generateString(7),
          title: t('link_data'),
          content: <LinkImport links={importData.links} onOk={onLinkImportOk} goPrevious={prev} mappingData={linkMappingData}/>,
        },
        {
          key: generateString(7),
          title: t('data_sending'),
          content: <ImportResume activityRequests={taskDataToSend} goPrevious={prev} linkRequests={linkDataToSend}/>,
        },
      ];
    return <div className="flex flex-grow flex-col h-full">
    <Steps className="my-1" size="small" current={current} items={steps} />
    <div className="steps-content flex-grow overflow-auto">{steps[current].content}</div>
  </div>
}

export default ImportExcelStepper;